






import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: { OUserAdd: () => import('@/components/organisms/panel/user/o-user-add.vue') }
})
